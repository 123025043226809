import AutoNumeric from 'autonumeric';

import 'bootstrap/js/dist/tab';
require('twitter-bootstrap-wizard')
import Rails from "@rails/ujs"
import I18n from 'i18n-js/index.js.erb'
// require("select2/dist/css/select2")
// require("src/ekycs/DetectRTC")
// require("src/ekycs/adapter")

import gtag, { install } from 'ga-gtag';
install('G-VZZCC8MLD1');

require("src/ekycs/hkid_main")
require("src/ekycs/address_main")
require("src/ekycs/card_main")
import validate from 'jquery-validation'
if (document.documentElement.lang === "zh-HK") {
  require("jquery-validation/dist/localization/messages_zh_TW")
}


/*-----------------------------------------------
|   Bootstrap Wizard
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  I18n.locale = $('body').data('locale');
  // var hkid_error_msg = I18n.t("ekyc_webcam_not_detected")
  // var hkid_error_msg = "Identify verification is not yet completed";
  // if (document.documentElement.lang == "zh-HK") {
  //   hkid_error_msg = "尚未成功核實身份";
  // }

  // var address_error_msg = "Address verification is not yet completed";
  // if (document.documentElement.lang == "zh-HK") {
  //   address_error_msg = "尚未成功核實地址";
  // }
  //   var financials_error_msg = "Financials verification is not yet completed";
  // if (document.documentElement.lang == "zh-HK") {
  //   financials_error_msg = "尚未成功核實財務狀態";
  // }


  var Selector = {
    DATA_WIZARD: '[data-wizard-submit]',
    PREVIOUS_BUTTON: '.previous .btn',
    TAB_PANE: '.tab-pane',
    FORM_VALIDATION: '.form-validation',
    NAV_ITEM_CIRCLE: '.nav-item-circle',
    NAV_ITEM: '.nav-item',
    NAV_LINK: '.nav-link',
    WIZARD_LOTTIE: '.wizard-lottie'
  };
  var ClassName = {
    ACTIVE: 'active',
    DONE: 'done',
    NAV: 'nav'
  };
  var DATA_KEY = {
    OPTIONS: 'options',
    WIZARD_STATE: 'wizard-state',
    CONTROLLER: 'controller',
    ERROR_MODAL: 'error-modal'
  };
  var wizards = $(Selector.DATA_WIZARD);

  var isFormValidate = function isFormValidate($currentTab) {
    var $currentTabForms = $currentTab.find(Selector.FORM_VALIDATION);
    var isValidate = true;
    $currentTabForms.each(function (i, v) {
      isValidate = $(v).valid();
      return isValidate;
    });
    var $success_hkid_div = $currentTab.find("#hkid_success");
    if ($success_hkid_div.hasClass("d-none")) {
      $("#hkid_verify_not_completed").text(I18n.t("ekyc.identity_verification_form_validation_error_alert"));
      $("#hkid_verify_not_completed").removeClass("d-none");

      isValidate = false
    }
    var $success_address_div = $currentTab.find("#address_success");
    if ($success_address_div.hasClass("d-none")) {
      $("#address_verify_not_completed").text(I18n.t("ekyc.address_verification_form_validation_error_alert"));
      $("#address_verify_not_completed").removeClass("d-none");

      isValidate = false
    }
    var $success_financials_div = $currentTab.find("#financials_success");
    if ($success_financials_div.hasClass("d-none")) {
      $("#financials_verify_not_completed").text(I18n.t("ekyc.financials_verification_form_validation_error_alert"));
      $("#financials_verify_not_completed").removeClass("d-none");

      isValidate = false
    }
    var $success_card_div = $currentTab.find("#card_success");
    if ($success_card_div.hasClass("d-none")) {
      $("#card_verify_not_completed").text(I18n.t("ekyc.card_verification_form_validation_error_alert"));
      $("#card_verify_not_completed").removeClass("d-none");

      isValidate = false
    }
    return isValidate;
  };

 

  !!wizards.length && wizards.each(function (index, value) {
    var $this = $(value);
    var controller = $this.data(DATA_KEY.CONTROLLER);
    var $controller = $(controller);
    var $buttonPrev = $controller.find(Selector.PREVIOUS_BUTTON);
    var $modal = $($this.data(DATA_KEY.ERROR_MODAL));
    var $lottie = $(value).find(Selector.WIZARD_LOTTIE);
    var options = $.extend({
      container: value.querySelector(Selector.WIZARD_LOTTIE),
      renderer: 'svg',
      loop: true,
      autoplay: false,
      name: 'Hello World'
    }, $lottie.data(DATA_KEY.OPTIONS));
    var animation = window.bodymovin.loadAnimation(options);
    $this.bootstrapWizard({
      tabClass: ClassName.NAV,
      onNext: function onNext(tab, navigation, idx) {
        var $currentTab = $this.find(Selector.TAB_PANE).eq(idx - 1);
        if (isFormValidate($currentTab)) {
        	// submitForm($currentTab)
        	return true
        } else {
        	return false;
        }
        
      },
      onTabClick: function onTabClick(tab, navigation, idx, clickedIndex) {
        return false
        var stepDone = $this.find(".nav-item:nth-child(" + (clickedIndex + 1) + ") .nav-link").data(DATA_KEY.WIZARD_STATE);
        if (clickedIndex > idx) {
          return false
        }
        // return true
        if (stepDone === 'done') {
          $modal.modal('show');
          return false;
        }

        if (clickedIndex <= idx) {
          return true;
        }

        var isValid = true;
        $this.find(Selector.TAB_PANE).each(function (tabIndex, tabValue) {
          if (tabIndex < clickedIndex) {
            $this.bootstrapWizard('show', tabIndex);
            isValid = isFormValidate($(tabValue));
          }

          return isValid;
        });
        return isValid;
      },
      onTabShow: function onTabShow(tab, navigation, idx) {
        var length = navigation.find('li').length - 1;
        idx === 0 ? $buttonPrev.hide() : $buttonPrev.show();
        idx === length && setTimeout(function () {
          return animation.play();
        }, 300);
        $this.find(Selector.NAV_LINK).removeClass(ClassName.DONE);
        $this.find(Selector.NAV_ITEM).each(function (i, v) {
          var link = $(v).find(Selector.NAV_LINK);

          // Application Review Page
          if (idx === length && !link.hasClass(ClassName.ACTIVE)) {
            link.attr('data-wizard-state', 'done');
            
            
          }

          if (!link.hasClass(ClassName.ACTIVE)) {
            link.addClass(ClassName.DONE);
            return true;
          }

          if (idx === length) {
            link.addClass(ClassName.DONE);
            $controller.hide();
          }

          if (idx < length) {
            $controller.show();
            window.scrollTo(0, 0);
          }


          return false;
        });
      }
    });
  });
});

const update_card_instalment_saltedge_connection = (card_instalment_id, saltedge_connection_id, stage) => {
  Rails.ajax({
        url: "/card_instalments/update_saltedge_connection",
        type: "post",
        beforeSend(xhr, options) {
          xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
          // Workaround: add options.data late to avoid Content-Type header to already being set in stone
          // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
          options.data = JSON.stringify({
            card_instalment_id: card_instalment_id,
            saltedge_connection_id: saltedge_connection_id,
            stage: stage
          })
          return true
        },
        success: function(data) {
          console.log(`update_card_instalment_saltedge_connection success: ${data}`)
        },
        error: function(data) {
          console.log(`update_card_instalment_saltedge_connection error: ${data}`)
        }
      })
}

document.addEventListener("turbolinks:load", function() {
  window.addEventListener("message", function(event) {
    console.log(event.data)
    if ("data" in event && event.data.length) {
      try {
        var parsed = JSON.parse(event.data)
      if ("data" in parsed) {
        if (parsed.data.stage == "success") {
          gtag('event', 'bank_connected', {
            "user_id": $("input[name='user_id']").val(),
            "stage": parsed.data.stage,
            "api_stage": parsed.data.api_stage,
            "connection_id": parsed.data.connection_id
          });
          $("#financial_verification_container").addClass("d-none");
          $("#Financials_success").removeClass("d-none");
          if ($("#card_instalment_id").length != 0) {
            $("#application_form_next_btn").prop("disabled", false)
          }
        } else if (parsed.data.stage == "fetching") {
          gtag('event', 'bank_connected', {
            "user_id": $("input[name='user_id']").val(),
            "stage": parsed.data.stage,
            "api_stage": parsed.data.api_stage,
            "connection_id": parsed.data.connection_id
          });

        } else if (parsed.data.stage == "error") {
          gtag('event', 'bank_connected', {
            "user_id": $("input[name='user_id']").val(),
            "stage": parsed.data.stage,
            "api_stage": parsed.data.api_stage,
            "connection_id": parsed.data.connection_id
          });
        }
        if ($("#card_instalment_id").length != 0) {
          update_card_instalment_saltedge_connection($("#card_instalment_id").val(), parsed.data.connection_id, parsed.data.stage)
        }
      }
      } catch(e) {
        // statements
        console.log(e);
      }
      
    }
  });

  $("#submit_file_form").on("ajax:success", (event) => {
    console.log(event)
      console.log("success")
      gtag('event', 'application_verification_complete', {"user_id": $("input[name='user_id']").val()});
      Turbolinks.visit('/dashboard')
      // $("#update_loan_information_form").append xhr.responseText
  }).on("ajax:error", (event) => {
    console.log("error")
      // $("#update_loan_information_form").append "<p>ERROR</p>"
  });
  

  
});
