//= require i18n
//= require i18n/translations
import I18n from 'i18n-js/index.js.erb'
var DetectRTC = require('detectrtc');
import gtag, { install } from 'ga-gtag';
install('G-VZZCC8MLD1');
// var takeSnapshotUI = createClickFeedbackUI();

var video;
var takePhotoButton;
var toggleFullScreenButton;
var switchCameraButton;
var amountOfCameras = 0;
var currentFacingMode = 'user';
var retry = 0;
var hasWebcam = false;


document.addEventListener("turbolinks:load", function() {
    I18n.locale = $('body').data('locale')
    takePhotoButton = document.getElementById('address_take_photo_button');
    // var startProcessButton = document.getElementById('address_start_processs_button');
    $("#address_start_processs_button").click(() => {
        $("#address_img-example").addClass("overlay_first");
        $("#address_img-frame").addClass("overlay_hold");
        $("#address_start_processs_button").addClass("d-none");
        $("#address_take_photo_button").removeClass("d-none");
        start_ekyc_process()
    })

});

const start_ekyc_process = () => {
    // do some WebRTC checks before creating the interface
    // I18n.locale = $('body').data('locale')
    DetectRTC.load(function() {
        // do some checks
        //          alert(
        //   'RTC Debug info: ' +
        //     '\n OS:                   ' +
        //     DetectRTC.osName +
        //     ' ' +
        //     DetectRTC.osVersion +
        //     '\n browser:              ' +
        //     DetectRTC.browser.fullVersion +
        //     ' ' +
        //     DetectRTC.browser.name +
        //     '\n is Mobile Device:     ' +
        //     DetectRTC.isMobileDevice +
        //     '\n has webcam:           ' +
        //     DetectRTC.hasWebcam +
        //     '\n has permission:       ' +
        //     DetectRTC.isWebsiteHasWebcamPermission +
        //     '\n getUserMedia Support: ' +
        //     DetectRTC.isGetUserMediaSupported +
        //     '\n isWebRTC Supported:   ' +
        //     DetectRTC.isWebRTCSupported +
        //     '\n WebAudio Supported:   ' +
        //     DetectRTC.isAudioContextSupported +
        //     '\n is Mobile Device:     ' +
        //     DetectRTC.isMobileDevice +
        //     '\n videoInputDevices: ' +
        //     DetectRTC.videoInputDevices.length +
        //     '\n version: ' +
        //     DetectRTC.version,
        // );
        // if (DetectRTC.isWebRTCSupported == false) {
          
        //     // if ($("body").data("locale") == "en") {
        //     //     alert("<%=I18n.t(:ekyc_webcam_not_detected, locale: :en)%>")
        //     // } else {
        //     //     alert("<%=I18n.t(:ekyc_webcam_not_detected, locale: :zh_HK)%>")
        //     // }
        //     // var oldURL = document.referrer;
        //     // window.location = oldURL + '&address_not_supported=true';

        // }
        // hasWebcam = true;
        if (DetectRTC.hasWebcam == false || DetectRTC.isWebRTCSupported == false) {
            alert(I18n.t("ekyc_webcam_not_detected"))
            // alert(I18n.t("ekyc_rtc_not_supported"));
            
            // var oldURL = document.referrer;
            // window.location = oldURL + '&address_not_supported=true';
            $("#address_ekyc_container").addClass("d-none");
                $("#manual_upload_address").removeClass("d-none");
        } else {
            amountOfCameras = DetectRTC.videoInputDevices.length;

            initCameraUI();
            initCameraStream();
        }


    });
    if (hasGetUserMedia()) {
        var errorCallback = function(e) {
            if (hasWebcam == true) {
                alert(I18n.t("ekyc_rtc_not_supported"))
                    // if ($("body").data("locale") == "en") {
                    //     alert("<%=I18n.t(:camera_permission_info, locale: :en)%>")
                    // } else {
                    //     alert("<%=I18n.t(:camera_permission_info, locale: :zh_HK)%>")
                    // }
                // window.location = document.referrer;
                $("#address_ekyc_container").addClass("d-none");
                $("#manual_upload_address").removeClass("d-none");
            }
        };

        // Not showing vendor prefixes.
        navigator.getUserMedia({
            video: true,
            audio: true
        }, function(localMediaStream) {
            navigator.mediaDevices.enumerateDevices()
                .then(function(devices) {
                    devices.forEach(function(device) {
                        /* alert(device.kind + ": " + device.label +
                           " id = " + device.deviceId);*/
                    });
                })
                .catch(function(err) {
                    alert(err.name + ": " + err.message);
                });
        }, errorCallback);
    } else {
        // $("#address_ekyc_container").addClass("d-none");
        // $("#manual_upload_address").removeClass("d-none");
    }

};

function hasGetUserMedia() {
    return !!(navigator.getUserMedia || navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia || navigator.msGetUserMedia);
}



function initCameraUI() {
    video = document.getElementById('address_video');


    // toggleFullScreenButton = document.getElementById('toggleFullScreenButton');
    // switchCameraButton = document.getElementById('switchCameraButton');

    // https://developer.mozilla.org/nl/docs/Web/HTML/Element/button
    // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_button_role

    takePhotoButton.addEventListener('click', function() {
        // takeSnapshotUI();
        takeSnapshot();
    });

    // -- fullscreen part

    // function fullScreenChange() {
    //   if (screenfull.isFullscreen) {
    //     toggleFullScreenButton.setAttribute('aria-pressed', true);
    //   } else {
    //     toggleFullScreenButton.setAttribute('aria-pressed', false);
    //   }
    // }

    // if (screenfull.isEnabled) {
    //   screenfull.on('change', fullScreenChange);

    // toggleFullScreenButton.style.display = 'block';

    // $('#gui_controls').on('change', '#photo_input', function(){ 
    //       submit_image($('#photo_input')[0].files[0]); 
    //     });
    //   toggleFullScreenButton.addEventListener('click', function() {
    //     $("#photo_input").click();

    //   });

    // -- switch camera part
    // if (amountOfCameras > 1) {
    //   switchCameraButton.style.display = 'block';

    //   switchCameraButton.addEventListener('click', function() {
    //     if (currentFacingMode === 'environment') currentFacingMode = 'user';
    //     else currentFacingMode = 'environment';

    //     initCameraStream();
    //   });
    // }

    // Listen for orientation changes to make sure buttons stay at the side of the
    // physical (and virtual) buttons (opposite of camera) most of the layout change is done by CSS media queries
    // https://www.sitepoint.com/introducing-screen-orientation-api/
    // https://developer.mozilla.org/en-US/docs/Web/API/Screen/orientation
    // window.addEventListener(
    //     'orientationchange',
    //     function() {
    //         // iOS doesn't have screen.orientation, so fallback to window.orientation.
    //         // screen.orientation will
    //         if (screen.orientation) angle = screen.orientation.angle;
    //         else angle = window.orientation;

    //         // var guiControls = document.getElementById('gui_controls').classList;
    //         var vidContainer = document.getElementById('address_vid_container').classList;

    //         if (angle == 270 || angle == -90) {
    //             guiControls.add('left');
    //             vidContainer.add('left');
    //         } else {
    //             // if (guiControls.contains('left')) guiControls.remove('left');
    //             if (vidContainer.contains('left')) vidContainer.remove('left');
    //         }

    //         //0   portrait-primary
    //         //180 portrait-secondary device is down under
    //         //90  landscape-primary  buttons at the right
    //         //270 landscape-secondary buttons at the left
    //     },
    //     false,
    // );
}

// https://github.com/webrtc/samples/blob/gh-pages/src/content/devices/input-output/js/main.js
function initCameraStream() {
    // stop any active streams in the window
    if (window.stream) {
        window.stream.getTracks().forEach(function(track) {
            track.stop();
        });
    }

    // we ask for a square resolution, it will cropped on top (landscape)
    // or cropped at the sides (landscape)
    var size = 1280;

    var constraints = {
        audio: false,
        video: {
            // width: { ideal: size },
            // height: { ideal: size },
            //width: { min: 1024, ideal: window.innerWidth, max: 1920 },
            //height: { min: 776, ideal: window.innerHeight, max: 1080 },
            facingMode: 'environment',
        },
    };

    navigator.mediaDevices
        .getUserMedia(constraints)
        .then(handleSuccess)
        .catch(handleError);

    function handleSuccess(stream) {
        window.stream = stream; // make stream available to browser console
        video.srcObject = stream;

        // if (constraints.video.facingMode) {
        //     if (constraints.video.facingMode === 'environment') {
        //         switchCameraButton.setAttribute('aria-pressed', true);
        //     } else {
        //         switchCameraButton.setAttribute('aria-pressed', false);
        //     }
        // }

        const track = window.stream.getVideoTracks()[0];
        const settings = track.getSettings();
        var str = JSON.stringify(settings, null, 4);
        console.log('settings ' + str);

        if (window.orientation === undefined) {
            if (settings.height / 9 * 16 == settings.weight) {
                $("#vid_container-address").addClass("embed-responsive-16by9");
                $("#vid_container-address").removeClass("embed-responsive-4by3");
                $("#vid_container-address").removeClass("embed-responsive-3by4");
            } else if (settings.height / 4 * 3 == settings.weight) {
                $("#vid_container-address").removeClass("embed-responsive-16by9");
                $("#vid_container-address").removeClass("embed-responsive-4by3");
                $("#vid_container-address").addClass("embed-responsive-3by4");
            } else {
                $("#vid_container-address").removeClass("embed-responsive-16by9");
                $("#vid_container-address").addClass("embed-responsive-4by3");
                $("#vid_container-address").removeClass("embed-responsive-3by4");
            }
        } else if ([0, 180].includes(window.orientation)) {
            $("#vid_container-address").removeClass("embed-responsive-16by9");
            $("#vid_container-address").removeClass("embed-responsive-4by3");
            $("#vid_container-address").addClass("embed-responsive-3by4");
        } else {
            if (settings.height / 9 * 16 == settings.weight) {
                $("#vid_container-address").addClass("embed-responsive-16by9");
                $("#vid_container-address").removeClass("embed-responsive-4by3");
                $("#vid_container-address").removeClass("embed-responsive-3by4");
            } else if (settings.height / 4 * 3 == settings.weight) {
                $("#vid_container-address").removeClass("embed-responsive-16by9");
                $("#vid_container-address").removeClass("embed-responsive-4by3");
                $("#vid_container-address").addClass("embed-responsive-3by4");
            } else {
                $("#vid_container-address").removeClass("embed-responsive-16by9");
                $("#vid_container-address").addClass("embed-responsive-4by3");
                $("#vid_container-address").removeClass("embed-responsive-3by4");
            }
        }
        //return navigator.mediaDevices.enumerateDevices();
    }

    function handleError(error) {
        console.log(error);
        var r = confirm(I18n.t('address_ekyc_failed_verification_alert'));
        if (r == true) {
            $("#address_ekyc_container").addClass("d-none");
            $("#manual_upload_address").removeClass("d-none");
        }
        //https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
        if (error === 'PermissionDeniedError') {
            alert('Permission denied. Please refresh and give permission.');
        }
    }

}

function submit_image(imagefile) {
    const url = "https://ekycms.credithero.xyz/api/verify_address"
    // const url = "https://ekycms-staging.credithero.xyz/api/verify_address"
    // const url = "http://0.0.0.0:5000/api/verify_address"
    var application_id = null;
    if ($("#application_id_field").length > 0) {
        application_id = document.getElementById("application_id_field").value
    }
    var card_instalment_id = null;
    if ($("#card_instalment_id_field").length > 0) {
        card_instalment_id = document.getElementById("card_instalment_id_field").value
    }
    function reqListener() {
        if (this.status != 200) {
            retry += 1
            if (retry > 2) {
                var r = confirm(I18n.t('address_ekyc_failed_verification_alert'));
                if (r == true) {
                    $("#address_ekyc_container").addClass("d-none");
                    $("#manual_upload_address").removeClass("d-none");
                }
            } else {
                var verified = alert(I18n.t('ekyc.address_verification_retry_alert', {remaining_trials: 3-retry}))
                $("#camera_icon").removeClass("d-none");
                $("#address_loading_spinner").addClass("d-none");
                $("#address_take_photo_button").removeClass("disabled");

            }
        } else {
            var jsonResponse = JSON.parse(this.responseText);
            if (jsonResponse.verified == true) {
                var verified = alert(I18n.t('address_ekyc_verified_alert'))
                // window.location = '/applications/submit_file?application_id=' + application_id;
                window.stream.getTracks()[0].stop();
                $("#address_ekyc_container").addClass("d-none");
                $("#address_success").removeClass("d-none");
                $("#address_verify_not_completed").addClass("d-none");
                gtag('event', 'address_verification_complete', {"user_id": $("input[name='user_id']").val()});
            } else {
                retry += 1
                if (retry > 2) {
                    var r = confirm(I18n.t('address_ekyc_failed_verification_alert'));
                    if (r == true) {
                        window.stream.getTracks()[0].stop();
                        $("#address_ekyc_container").addClass("d-none");
                        $("#manual_upload_address").removeClass("d-none");
                        
                    }
                } else {
                    var verified = alert(I18n.t('ekyc.address_verification_retry_alert', {remaining_trials: 3-retry}))
                    $("#camera_icon").removeClass("d-none");
                    $("#address_loading_spinner").addClass("d-none");
                    $("#address_take_photo_button").removeClass("disabled");
                }

            }
        }

        // $("#loading_submit_application_alert").css("visibility", "hidden");
        // $("#container").css("visibility", "visible");
    }
    var formData = new FormData();
    formData.append("address", document.getElementById("address_field").value);
    formData.append("name", document.getElementById("name_field").value);
    if ($("#application_id_field").length > 0) {
        formData.append("application_id", document.getElementById("application_id_field").value);
    }
    if ($("#card_instalment_id_field").length > 0) {
        formData.append("card_instalment_id", document.getElementById("card_instalment_id_field").value);
    }
    // formData.append("application_id", document.getElementById("application_id_field").value);
    // formData.append("card_instalment_id", document.getElementById("card_instalment_id_field").value);
    formData.append("imagefile", imagefile);

    var request = new XMLHttpRequest();
    request.onreadystatechange = function() { /* .. */ };
    request.addEventListener("load", reqListener);
    request.open("POST", url);
    request.send(formData);

    $("#camera_icon").addClass("d-none");
    $("#address_loading_spinner").removeClass("d-none");
    $("#address_take_photo_button").addClass("disabled");
}

function takeSnapshot() {
    // if you'd like to show the canvas add it to the DOM
    var canvas = document.createElement('canvas');

    var width = video.videoWidth;
    var height = video.videoHeight;

    canvas.width = width;
    canvas.height = height;

    var context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, width, height);

    // polyfil if needed https://github.com/blueimp/JavaScript-Canvas-to-Blob

    // https://developers.google.com/web/fundamentals/primers/promises
    // https://stackoverflow.com/questions/42458849/access-blob-value-outside-of-canvas-toblob-async-function
    function getCanvasBlob(canvas) {
        return new Promise(function(resolve, reject) {
            canvas.toBlob(function(blob) {
                resolve(blob);
            }, 'image/jpeg');
        });
    }

    // some API's (like Azure Custom Vision) need a blob with image data
    getCanvasBlob(canvas).then(function(blob) {
        // do something with the image blob
        submit_image(blob)

    });
}